// Migrated
<template lang="pug">
ResponsiveBackground(
  :image="image",
  :height="imgHeight"
  :align="imgAlign"
  :class="['page-title', `banner-type--${bannerType.toLowerCase()}`, gradientMask || 'no-gradient-mask', `${false ? 'with-gradient-overlay' : ''}`]"
)
  .container-fluid.container-content
    .row
      .col-xl-10.col-12.mx-auto
        .row.justify-content-between
          .col-xl-3.col-lg-3(v-if="!offline && !isCharter")
          div(:class="contentClasses")
            .page-title-content
              template(v-if="bannerType === 'CAMPAIGN'")
                h4(v-show="subtitle") {{ subtitle }}
                h1(v-show="title") {{ title }}
              template(v-else)
                h1(v-show="title") {{ title }}
                h4(v-show="subtitle") {{ subtitle }}
              slot
</template>

<script>
import { mapState } from 'pinia'

import hotelImagesMixin, { supplierURLs } from '@/mixins/hotel-images.mixin'

export default defineNuxtComponent({
  mixins: [hotelImagesMixin],

  props: {
    background: {
      type: [String, Array, Object],
      required: true
    },

    title: {
      type: String,
      default: null
    },

    subtitle: {
      type: String,
      default: null
    },

    bannerType: {
      type: String,
      default: 'CAMPAIGN'
    },

    imgHeight: {
      type: Number,
      default: 420
    },

    imgAlign: {
      type: String,
      default: 'center'
    },

    offline: {
      type: Boolean,
      default: false
    },

    gradientMask: {
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      imageType: 'cloudinary',
      image: ''
    }
  },

  computed: {
    ...mapState(useLocaleStore, {
      isCharter: 'isCharter'
    }),

    contentClasses () {
      return [
        `align-self-${this.bannerType === 'CAMPAIGN' ? 'center' : 'end'}`,
        `justify-self-${this.bannerType === 'CAMPAIGN' ? 'center' : 'end'}`,
        ...(!this.isCharter ? ['col-xl-9', 'col-lg-9'] : ['col-12'])
      ]
    }
  },

  watch: {
    background () {
      this.evalImage()
    }
  },

  mounted () {
    this.evalImage()
  },

  methods: {
    evalImage () {
      if (Array.isArray(this.background)) {
        this.imageType = 'url'
        this.image = (
          this.background.find(i => i?.maxwidth >= 1440)
          || this.background[this.background.length - 1]
        ).href
        return
      }

      if (this.background instanceof Object) {
        const i = this.background
        if (Array.isArray(i?.image)) {
          const arrayImg = (
            i.image.find(i => i?.maxwidth >= 1440)
            || i.image[i.length - 1]
          )
          if (arrayImg?.href) {
            return arrayImg?.href
          }
        }
        this.imageType = i?.image_type || this.hotel.meta.images_type || this.hotel.supplier
        const url = supplierURLs[this.imageType]
          .replace('[PATH]', i?.image || i)
        this.image = this.imageSize(url, 'og')
        return
      }

      this.imageType = 'cloudinary'
      this.image = this.background
    },
  }
})
</script>

<style lang="scss">
@import "@/assets/scss/modules/_page-title";
.no-gradient-mask {
  &::before {
    display: none;
  }
}
</style>
